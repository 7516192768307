import React, {useState, useEffect} from "react"; 
import {connect} from "react-redux"; 
import {Container,  Button} from "react-bootstrap"; 
import "./login.css"; 
import {setAppleToken, setSpotifyData, setSpotifyAccessToken} from "../home/homeslice"; 
import queryString from "query-string"; 
import {useNavigate, useLocation} from "react-router-dom"; 
import axios from "axios"; 
import SpotifyWebApi from "spotify-web-api-node";
import {endpoint} from "../endpoint";
import parameters from "../../parameters.json"; 


import AppleLogo from "../photos/apple-logo.png"
import SpotifyLogo from "../photos/spotify-logo.png"



function Login({setAppleToken, appleToken, setSpotifyAccessToken, setSpotifyData, spotifyToken}) { 
    const navigate = useNavigate(); 
    const {code, state} = queryString.parse(useLocation().search); 
    

    useEffect(() => {
        setAppleToken(localStorage.getItem("appleToken")); 
        setSpotifyAccessToken(localStorage.getItem("spotifyAccess"))
        const my_spot = new SpotifyWebApi({accessToken: localStorage.getItem("spotifyAccess")})
        my_spot.getMe().then(data => {
            setSpotifyData(data)
        }).catch(err => {
            setSpotifyAccessToken(null)
            localStorage.removeItem("spotifyAccess")
        })
    }, [])

    useEffect(() => {
        if (code != null && spotifyToken == null) {
            axios.post(endpoint + "/authorize2", {code: code}).then(
                res => {
                    localStorage.setItem("spotifyAccess", res.data["body"]["access_token"])
                    setSpotifyAccessToken(res.data["body"]["access_token"])
                    const my_spot = new SpotifyWebApi({accessToken: res.data["body"]["access_token"]})
                    my_spot.getMe().then(data => {
                        setSpotifyData(data)
                    }).catch(err => {
                        console.log(err)
                    })
            
                }
            )
        
        }
    }, [code, state])




    async function authorizeApple() {
    
        var music = window.MusicKit.getInstance();
        if (music == null) {
            window.MusicKit.configure({
                developerToken: parameters["devtoken"], 
                app: {
                    name: "Avi", 
                    build: "1.0", 
                    version: "1.0"
                }, 
            })
            music = window.MusicKit.getInstance();
        }
        music.authorize().then(data => {
            console.log(data)
            localStorage.setItem("appleToken", data)
            setAppleToken(data)
            window.MusicKit.configure({
                developerToken: parameters["devtoken"], 
                app: {
                    name: "Avi", 
                    build: "1.0", 
                    version: "1.0"
                }, 
                musicUserToken: data
            })
        }).catch(error => {
            alert(error)
        })
    }

    function authorizeSpotify() {
        axios.post(endpoint + "/authorize").then(
            res => window.open(res.data.url, "_self")
        )
    }

    return ( 
        <Container fluid className = "login"> 
        <div className = "header">
            <h1> Music Migration</h1>
            <p> Want to switch music streaming platforms but don't want to lose all your playlists? Worry no more, this web app will help you transfer your playlists from Apple Music to Spotify 
                or from Spotify to Apple Music. 
            </p>
        </div> 
        <div className = "columns">
            <div className = "apple column">
                <img src = {AppleLogo} />
                {appleToken == null && <Button onClick = {() => authorizeApple()}> Login </Button>}
                {appleToken != null && <Button disabled> Logged In </Button>}
            </div> 
            <Button className = "transferbutton" onClick = {() => navigate("/home")} disabled = {appleToken == null || spotifyToken == null}> Transfer </Button>
            <div className = "spotify column">
                <img src = {SpotifyLogo} />
                {spotifyToken == null && <Button onClick = {() => authorizeSpotify()}> Login </Button>}
                {spotifyToken != null && <Button disabled > Logged In </Button>}
            </div> 
        </div> 
        </Container> 
    )
}

const mapStateToProps = state => ({
    appleToken: state.home.appleToken, 
    spotifyToken: state.home.spotifyaccessToken
})

const mapDispatchToProps = {setAppleToken, setSpotifyAccessToken, setSpotifyData}

export default connect(mapStateToProps, mapDispatchToProps)(Login);