import React, {useState, useEffect} from "react"; 
import {connect} from "react-redux"; 
import {Container,  Button} from "react-bootstrap"; 
import "./home.css"; 
import {setAppleToken, setSpotifyAccessToken} from "./homeslice"; 
import {useNavigate, useLocation} from "react-router-dom"; 
import axios from "axios"; 
import {endpoint} from "../endpoint";
import parameters from "../../parameters.json";
import SpotifyWebApi from 'spotify-web-api-node';
import PlaylistIcon from "../photos/playlist_icon.png"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faApple, faSpotify} from "@fortawesome/free-brands-svg-icons"
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons"

function Home({appleToken, spotdata, spotifyAccessToken}) { 
    const navigate = useNavigate(); 
    const [spotifyplaylist, setSpotifyPlaylists] = useState([]); 
    const [appleplaylist, setApplePlaylist] = useState([]); 
    const [updatePlaylist, setUpdatePlaylist] = useState(false);

    async function addPlaylists(id, offset, all_playlist) {
        const my_spotify = new SpotifyWebApi({accessToken: spotifyAccessToken})
        await my_spotify.getUserPlaylists(id, {limit: 50, offset: offset}).then(
            function(data) {
                var item;
                for (item of data.body["items"]) {
                    var playlist = {
                        id: item["id"], 
                        images: item["images"], 
                        name: item["name"]
                    }
                    all_playlist.push(playlist);
                }
                if (data.body["next"]) { 
                        addPlaylists(id, offset + 50, all_playlist);
                }  else { 
                    setSpotifyPlaylists(all_playlist);
                }
       }).catch(err => {
        localStorage.removeItem("spotifyAccess")
        setSpotifyAccessToken(null)
        navigate("/")
       })
    }

    async function addSongs(id, offset, all_songs) {
        const my_spotify = new SpotifyWebApi({accessToken: spotifyAccessToken})
        await my_spotify.getPlaylistTracks(id, {offset: offset, fields: "items(track(album(images), artists(name),id, name)), next"}).then(
            function(data) {
                var item;
                const pack_songs = []
                for (item of data.body["items"]) {
                    const track = item["track"]

                    if (track) {
                        const album = track["album"]["images"][0]
                        const artist = track["artists"][0]
                        var song = {
                            image: album ? album["url"] : null , 
                            artist: artist ? artist["name"] : null, 
                            id: track["id"],
                            name: track["name"] 
                        }
                        pack_songs.push(song);
                    }
                }
                console.log(pack_songs)
                all_songs.push( ...pack_songs);
                if (data.body["next"]) { 
                        addSongs(id, offset + 100, all_songs);
                }
       })
    }

    async function addSongsApple(appleid, offset, all_songs) {
        // let music = window.MusicKit.getInstance();
        // window.MusicKit.configure({
        //     developerToken: parameters["devtoken"], 
        //     app: {
        //         name: "Avi", 
        //         build: "1.0", 
        //         version: "1.0"
        //     }, 
        //     musicUserToken: appleToken
        // })
        // music.authorize().catch(err => {
        //     console.log(err)
        // })
        // console.log(music);
        // music.api.library.playlist(appleid, { include: 'tracks' }).then(function(results) {
        //     console.log(results);
        //   }).catch(function(error) {
        //     window.alert(error);
        //   });


        await axios.get(`https://api.music.apple.com/v1/me/library/playlists/${appleid}/tracks`, {
            headers: {
                'Music-User-Token': appleToken, 
                "Content-type": "application/json",
                "Authorization": `Bearer ${parameters['devtoken']}`
            }, 
            params: {
                offset: offset
            }

         }).then(data => {
            all_songs.push(...data["data"]["data"])
            console.log(data["data"]["meta"])
            console.log(offset)
            if (data["data"]["meta"]["total"] > offset + 50) {
                addSongsApple(appleid, offset + 50, all_songs)
            }
        }).catch(data => {
            console.log(data)
            localStorage.removeItem("appleToken")
            setAppleToken(null)
            navigate("/")
        })
    }



    async function TransferSpotToApple(spotifyid, name) {
        var same = false;
        let playlist;
        for (playlist in appleplaylist) {
            if (playlist["attributes"]["name"] == name) {
                same = !window.confirm(`There is already an Apple Playlist in your library with the name ${name} - are you sure you want to continue?`)
                break;
            }
        }
        if (same) {
            return;
        }

        const songs = new Array();
        await addSongs(spotifyid, 0, songs);
        let id; 
        await axios.post("https://api.music.apple.com/v1/me/library/playlists",
        {
            attributes: {
                name: name, 
                description: "Migrated playlist from Spotify"
            }
        }, 
        {
            headers: {
                'Music-User-Token': appleToken, 
                "Content-type": "application/json", 
                "Authorization": `Bearer ${parameters['devtoken']}`
            }
         }, ).then(data => {   
             id = data["data"]["data"][0]["id"]
        }).catch(data => {
            const music = window.MusicKit.getInstance();
            music.authorize().then(data => {
                localStorage.setItem("appleToken", data)
                setAppleToken(data)
                window.MusicKit.configure({
                    developerToken: parameters["devtoken"], 
                    app: {
                        name: "Avi", 
                        build: "1.0", 
                        version: "1.0"
                    }, 
                    musicUserToken: data
                })
                TransferSpotToApple(spotifyid, name)
            }).catch(error => {
                navigate("/")
            }) 
        })
        let song; 
        let storefront;
        await axios.get("https://api.music.apple.com/v1/me/storefront", {
            headers: {
                'Music-User-Token': appleToken, 
                "Content-type": "application/json", 
                "Authorization": `Bearer ${parameters['devtoken']}`
            }, 
         }).then(data => 
            storefront = data["data"]["data"][0]["id"]
        ).catch(data => 
            storefront = "us"
        )
        const ids = []
        
        for (song in songs) {
            await axios.get(`https://api.music.apple.com/v1/catalog/${storefront}/search`, 
            {
                headers: {
                    'Music-User-Token': appleToken, 
                    "Content-type": "application/json", 
                    "Authorization": `Bearer ${parameters['devtoken']}`
                }, 
                params: {
                    term: songs[song]["name"]
                }
             }).then(data => {
                if (data["data"]["results"]["songs"]["data"].length > 0) {

                    ids.push({"id": data["data"]["results"]["songs"]["data"][0]["id"]
                    , "type": "songs"
                    })
                }
             }).catch(err => {
                console.log(err)
            })
        }
        console.log(ids)
        await axios.post(`https://api.music.apple.com/v1/me/library/playlists/${id}/tracks`,
        {
            data: ids
        }, 
        {
            headers: {
                'Music-User-Token': appleToken, 
                "Content-type": "application/json", 
                "Authorization": `Bearer ${parameters['devtoken']}`
            }
         }, ).then(data => {   
            setUpdatePlaylist(!updatePlaylist)
        }).catch(data => {
            console.log(data)
        })

    }

    async function TransferAppleToSpot(appleid, name) {
        var same = false;
        let playlist;
        for (playlist in spotifyplaylist) {
            if (spotifyplaylist[playlist]["name"] == name) {
                same = !window.confirm(`There is already a Spotify Playlist in your library with the name ${name} - are you sure you want to continue?`)
                break;
            }
        }
        if (same) {
            return;
        }
        
        const songs = new Array(); 
        await addSongsApple(appleid, 0, songs); 
        let id; 
        const my_spotify = new SpotifyWebApi({accessToken: spotifyAccessToken})
        await my_spotify.createPlaylist(name, {
            "description": "Migrated playlist from Apple", 
            "public": false
        }).then(data => {
            id = data["body"]["id"]
        }).catch((err) => {
            alert(err)
        })
        console.log(id)
        var ids = [] 
        let song; 
        for (song in songs) {
            let song_attribute = songs[song]["attributes"]
            let string = `track:${song_attribute['name']} artist: ${song_attribute['artistName']}`
            console.log(string)
            await my_spotify.searchTracks(string).then(data => {
                let tracks = data["body"]["tracks"]["items"]
                if (tracks.length > 0) {
                    ids.push(tracks[0]["uri"])
                }
            })
        }
        await my_spotify.addTracksToPlaylist(id, ids).then(data => {
            setUpdatePlaylist(!updatePlaylist)
        }).catch(err => {
            alert(err)
        })
    }

    useEffect(() => {
        axios.get("https://api.music.apple.com/v1/me/library/playlists", {
            headers: {
                'Music-User-Token': appleToken, 
                "Content-type": "application/json", 
                "Authorization": `Bearer ${parameters['devtoken']}`
            }
         }).then(data => {
             console.log(data)
            setApplePlaylist(data["data"]["data"])
        }).catch(data => {
            localStorage.removeItem("appleToken")
            setAppleToken(null)
            navigate("/")
        })

        addPlaylists(spotdata["body"]["id"], 0, new Array())


    }, [updatePlaylist])

    const apple_mapping = appleplaylist.map((playlist, index) => {
        return (
            <div className = "playlist" key = {index}>
                <img src = {PlaylistIcon}/>
                <h3> {playlist["attributes"]["name"]}</h3>
                <Button onClick = {() => TransferAppleToSpot(playlist.id, playlist["attributes"]["name"])}> Transfer </Button>
            </div>
        )
    })


    const spot_mapping = spotifyplaylist.map((playlist, index) => {
        const image = (playlist.images.length >= 2) ? playlist.images[playlist.images.length - 2] : playlist.images[playlist.images.length - 1] 
        const src = image ? image["url"] : PlaylistIcon 

        return (
            <div className = "playlist" key = {index}>
                <img src = {src}/>
                <h3> {playlist["name"]} </h3>
                <Button onClick = {() => TransferSpotToApple(playlist.id, playlist["name"])}> Transfer </Button>
            </div>
        )
    })

    return ( 
        <Container fluid className = "home"> 
            <div className = "header">
                <FontAwesomeIcon icon = {faApple} className = "applelogo" size = "8x"/>
                <FontAwesomeIcon icon = {faExchangeAlt} className = "exchange" size = "6x"/>
                <FontAwesomeIcon icon = {faSpotify} className = "spotifylogo" size = "8x"/>
                </div>
            <div className = "columns">
                
                <div className = "column apple">
                    
                    {apple_mapping}
                </div>
                <div className = "column spotify">
                    {spot_mapping}
                </div>
            </div>
        </Container> 
    )
}

const mapStateToProps = state => ({
    appleToken: state.home.appleToken, 
    spotifyAccessToken: state.home.spotifyaccessToken, 
    spotdata: state.home.spotdata
})

const mapDispatchToProps = {setAppleToken}

export default connect(mapStateToProps, mapDispatchToProps)(Home);