import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom"


import Login from "./components/login/login";
import Home from "./components/home/home"


function App() {

  useEffect(() => {

    console.log(window.MusicKit)
  }, [])

  return (
    <div className="App">
      <Routes>
      <Route exact path="/home" element = {<Home/>}></Route>
      <Route exact path="/" element = {<Login/>}> </Route>

      </Routes>
    </div>
  );
}

export default App;
