import {createSlice} from "@reduxjs/toolkit"; 

export const homeSlice = createSlice({
    name: "home", 
    initialState: {
        appleToken: null, 
        spotifyaccessToken: null, 
        spotdata: null, 
    }, 
    reducers : {
        setAppleToken: (state, action) => {
            state.appleToken = action.payload
        }, 
        setSpotifyAccessToken: (state, action) => {
            console.log(action.payload)
            state.spotifyaccessToken = action.payload;
        },
        setSpotifyData: (state, action) => {
            state.spotdata = action.payload; 
        }
    }
})

export const {setAppleToken, setSpotifyAccessToken, setSpotifyData} = homeSlice.actions; 

export default homeSlice.reducer;